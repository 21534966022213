<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="MEMBER TIER"
        :filter="filter"
        placeholder="Search Tier name"
        @search="handleSearch"
        :hasDropdown="false"
        :hasFilter="false"
        routerPath="/membertier/detail/0"
      />
      <div class="bg-white border-red p-2 p-lg-3 mt-3">
        <TableMemberListPanel
          :filter="filter"
          :pageOptions="pageOptions"
          :fields="fields"
          :items="items"
          :isBusy="isBusy"
          :rows="rows"
          :showingTo="showingTo"
          @filterPage="filterPage"
          @deleteMemberTier="deleteMemberTier"
          @handleChangeTake="handleChangePerpage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import TableMemberListPanel from "@/components/memberTier/TableMemberListPanel";
import HeaderPanel from "@/components/HeaderPanel";
export default {
  components: {
    OtherLoading,
    TableMemberListPanel,
    HeaderPanel,
  },
  data() {
    return {
      isLoading: true,
      filter: {
        page: 1,
        take: 10,
        search: "",
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      fields: [
        {
          key: "icon",
          label: "Icon",
          class: "w-50px",
        },
        {
          key: "name",
          label: "Name",
          class: "w-100px text-left",
        },
        {
          key: "member_level_code",
          label: "Code",
          class: "w-50px",
        },
        {
          key: "upgrade_tier_name",
          label: "Upgrade Tier",
          class: "w-100px",
        },
        {
          key: "downgrade_tier_name",
          label: "Downgrade Tier",
          class: "w-100px",
        },
        {
          key: "baht_per_point",
          label: "Baht Per Point",
          class: "w-100px",
        },
        {
          key: "maintain_baht",
          label: "Maintain/Upgrade",
          class: "w-100px",
        },
        {
          key: "membership_period",
          label: "Membership Period",
          class: "w-150",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
      items: [],
      isBusy: false,
      rows: 0,
      showingTo: 10,
      deleteId: 0,
    };
  },
  async created() {
    await this.getMemberTierList();
  },
  methods: {
    async getMemberTierList() {
      this.isBusy = true;
      await this.$store.dispatch("getMemberTierList", this.filter);
      const data = this.$store.state.memberTier.memberTierList;
      if (data.result === 1) {
        this.items = data.detail.detail;
        this.rows = data.detail.count;
        this.isLoading = false;
      }
      this.isBusy = false;
    },
    handleSearch() {
      this.getMemberTierList();
    },
    handleChangePerpage(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.showingTo = value;
      this.getMemberTierList();
    },
    filterPage(value) {
      this.filter.page = value;
      this.getMemberTierList();
    },
    hideModal() {
      if (this.deleteId == 0) {
        window.location.href = "/membertier";
      }
    },
    deleteMemberTier(id) {
      this.deleteId = id;
      this.confirmAlert().then((val) => {
        if (val.isConfirmed) {
          this.deleteMember(id);
        }
      });
    },
    async deleteMember(val) {
      if (val) {
        this.isLoading = true;
        await this.$store.dispatch("deleteMemberTier", this.deleteId);
        const data = this.$store.state.memberTier.deleteResp;
        if (data.result === 1) {
          this.successAlert().then(() => {
            this.getMemberTierList();
          });
        } else {
          this.isLoading = false;
          this.errorAlert(data.message);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
}
</style>
